// obtain cookieconsent plugin
var cc = initCookieConsent();

// example logo
var logo = 'Nastavení cookies';
var cookie = '';

// run plugin with config object
cc.run({
    current_lang : 'cs',
    autoclear_cookies : true,                   // default: false
    cookie_name: 'krusovice_cookies',             // default: 'cc_cookie'
    cookie_expiration : 30,                    // default: 182
    page_scripts: true,                         // default: false

    // auto_language: null,                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                           // default: true
    // delay: 0,                                // default: 0
    // force_consent: false,
    // hide_from_bots: false,                   // default: false
    // remove_cookie_tables: false              // default: false
    // cookie_domain: location.hostname,        // default: current domain
    // cookie_path: "/",                        // default: root
    // cookie_same_site: "Lax",
    // use_rfc_cookie: false,                   // default: false
    // revision: 0,                             // default: 0

    gui_options: {
        consent_modal: {
            layout: 'bar',                      // box,cloud,bar
            position: 'bottom center',           // bottom,middle,top + left,right,center
            transition: 'slide'                 // zoom,slide
        },
        settings_modal: {
            layout: 'box',                      // box,bar
            // position: 'left',                // right,left (available only if bar layout selected)
            transition: 'slide'                 // zoom,slide
        }
    },

    onFirstAction: function(){
    },

    onAccept: function (cookie) {
    },

    onChange: function (cookie, changed_preferences) {

        if (!cc.allowedCategory('analytics')) {
            typeof gtag === 'function' && gtag('consent', 'update', {
              ad_storage: 'denied',
              analytics_storage: 'denied',
              personalization_storage: 'denied',
            });
          } 
    },

    languages: {
        'en': {
            consent_modal: {
                title: '',
                description: 'Díky cookies pro vás bude prohlížení stránek příjemnější a jednodušší. Ke zpracování některých z nich potřebujeme váš souhlas, který dáte kliknutím na „Přijmout vše“, nebo můžete upravit své preference kliknutím na <button type="button" data-cc="c-settings" class="cc-link">„upravit nastavení“</button>. Více informací o používání <a href="/files/zasady-pouzivani-souboru-cookies.pdf">souborů cookies</a> a <a href="/files/zasady-ochrany-osobnich-udaju.pdf">ochraně osobních údajů</a>.',
                close_btn_label: 'Zavřít',
                primary_btn: {
                    text: 'Přijmout vše',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Odmítnou vše',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: logo,
                save_settings_btn: 'Uložit nastavení',
                accept_all_btn: 'Přijmout vše',
                reject_all_btn: 'Odmítnou vše',
                close_btn_label: 'Zavřít',
                cookie_table_headers: [
                    {col1: 'Název'},
                    {col2: 'Firma'},
                    {col3: 'Expirace'},
                ],
                blocks: [
                    {
                        title: '',
                        description: 'K podrobné analýze Vašich potřeb využíváme služby cookies, které můžete schválit kliknutím na tlačítko „Přijmout všechny“. V případě potřeby můžete cookies upravit v podrobném nastavení.'
                    }, {
                        title: 'TECHNICKÉ COOKIES',
                        description: 'Technické cookies potřebné k fungovaní webové prezentace',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'REKLAMNÍ COOKIES',
                        description: '',
                        toggle: {
                            value: 'reklams',     // there are no default categories => you specify them
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Google Tag Manager',
                                col2: 'google.com',
                                col3: '30 dní',
                                is_regex: true
                            },
                            {
                                col1: 'DoubleClick Floodlight Tag',
                                col2: 'floodlight.com',
                                col3: '30 dní',
                                is_regex: true
                            },
                            {
                                col1: 'Facebook Pixel Code',
                                col2: 'facebook.com',
                                col3: '30 dní',
                                is_regex: true
                            },
                            {
                                col1: 'Adform Tracking Code',
                                col2: 'adformhelp.com',
                                col3: '30 dní',
                                is_regex: true
                            },
                        ]
                    }, {
                        title: 'ANALYTICKÉ COOKIES',
                        description: '',
                        toggle: {
                            value: 'analytics',     // there are no default categories => you specify them
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: 'Google Analytics',
                                col2: 'google.com',
                                col3: '30 dní',
                                is_regex: true
                            },
                        ]
                    }
                ]
            }
        }
    }

});